import { throttle } from 'lodash';

import { hasStorageAvailable } from '@klaviyo-mkt/gatsby-components/shared/utils/storage';
import { storeLastViewedAcademyContent } from '@klaviyo-mkt/gatsby-components/organisms/universal-navigation/utils';

import type { GatsbyBrowser } from 'gatsby';

const resizeIframe = throttle((height: number) => {
  if (!height) return;

  const iframeContainer = document.getElementById('klaviyo-frame-container');
  if (!iframeContainer) return;

  const additionalHeight = 111;
  const difference = Math.abs(iframeContainer?.offsetHeight - height);
  const exceedsThreshold = difference > (additionalHeight + 1);

  if (exceedsThreshold) {
    iframeContainer.style.height = `${height + additionalHeight}px`;
  }
}, 1000);

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  const VALID_ORIGINS = [
    'https://cms.academy.klaviyo.com',
    'https://learning-content.klaviyo.com',
    window.location.origin,
  ];

  window.addEventListener('message', ({ data, origin }) => {
    if (VALID_ORIGINS.includes(origin)) {
      if (data === 'skilljarRegister') {
        // Set data for Academy redirect location banner
        storeLastViewedAcademyContent(window.location.pathname);

        window.location.href = 'https://cms.academy.klaviyo.com/auth/login';
      }
      
      if (data?.iframeResize && data?.height) {
        resizeIframe(data.height);
      }

      if (data?.pathname) {
        if (data.pathname.includes('/resume')) {
          // this is a course resume redirect
          // and we'll get another message with the correct path
          return;
        }

        if (data.pathname.includes('/scorm')) {
          // enable testing of scorm content
          // this has a unique URL structure we don't mirror on our site
          // so these urls will redirect and 404 if we don't handle them
          return;
        }

        if (
          window.location.pathname.includes('/profile')
          && data.pathname.includes('/profile')
        ) {
          return;
        }

        const url = new URL(window.location.href);

        const locale = 'en-us';
        const collectionsBase = `/${locale}/collections`;
        const coursesBase = `/${locale}/courses`;

        url.pathname = `${coursesBase}${data.pathname}`;

        if (data.pathname === '/') {
          // handle done button on profile page
          url.pathname = `/${locale}`;
        }

        if (data.pathname.startsWith('/path')) {
          url.pathname = `${collectionsBase}${data.pathname.replace('/path', '')}`;
        }

        if (data.pathname.startsWith('/accounts/profile')) {
          url.pathname = `/${locale}/profile`;
        }

        if (url.toString() !== window.location.href) {
          window.location.replace(url.toString());
        }
      }

      if (data?.skilljarUser) {
        // This is to store the user in session storage
        // UserContextProvider will listen for this event and update the state
        if (hasStorageAvailable('sessionStorage')) {
          const storedUser = sessionStorage.getItem('skilljarUser');
          if (storedUser !== data.skilljarUser) {
            sessionStorage.setItem('skilljarUser', JSON.stringify(data.skilljarUser));
            window.dispatchEvent(new Event('skillJarUserUpdated'));
          }
        }
      }
    }
  }, false);
};
